import { Link } from 'gatsby';
import * as React from 'react';
import Helmet from 'react-helmet';
import { animated, Trail } from 'react-spring';
import * as styles from './404.module.scss';

export default class NotFoundPage extends React.Component<{}, {}> {

  public render() {
    const title = <h1 key="title">404</h1>;
    const subtitle = (
      <p key="tagline">You're looking for photos that don't exist so you get a photo of me.</p>
    );
    const me = (
      <div key="me" className={styles.ImageContainer}>
        <img
          src="https://res.cloudinary.com/thecle/image/upload/v1546633919/404/2018-12-30_09.02.54.jpg"
          alt="Me gooning with some friends, featuring a Leica and Tesla because why not."
        />
      </div>
    );
    const back = <Link to="/" key="back" className={styles.Back}>&#8592; Back to safety</Link>;

    const items = [title, subtitle, me, back];
    const keys = ['title', 'tagline', 'me', 'back'];

    const tagTitle = 'cle?';
    const tagDescription = 'I think you got lost...';
    const tagImage = 'https://res.cloudinary.com/thecle/image/upload/v1546633919/404/2018-12-30_09.02.54.jpg';

    return (
      <div className={styles.Container}>
        <Helmet>
            {/* General tags */}
            <title>{tagTitle}</title>
            <meta name="description" content={tagDescription} />
            <meta name="image" content={tagImage} />

            {/* OpenGraph tags */}
            <meta property="og:url" content="https://thecle.me" />
            <meta property="og:title" content={tagTitle} />
            <meta property="og:description" content={tagDescription} />
            <meta property="og:image" content={tagImage} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@_christianle_" />
            <meta name="twitter:title" content={tagTitle} />
            <meta name="twitter:description" content={tagDescription} />
            <meta name="twitter:image" content={tagImage} />
        </Helmet>
        <Trail
          items={items}
          keys={keys}
          native={true}
          from={{ opacity: 0, transform: 'translateY(48px)' }}
          to={{ opacity: 1, transform: 'translateY(0px)' }}
        >
          {(item) => (props) => <animated.div style={props} className="item" children={item} />}
        </Trail>
      </div>
    );
  }
}
